import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ENVIRONMENT, Environment } from '@my-tomorrows/core-environment';
import { Observable, map } from 'rxjs';
import { AliasData } from '../interfaces/condition-aliases-response.interface';
import { ConditionsResponse } from '../interfaces/conditions-response.interface';
import { CountriesResponse } from '../interfaces/countries-response.interface';
import { TrialDetailsRequest } from '../interfaces/trial-details/trial-details-request.interface';
import { TrialDetailsResponse } from '../interfaces/trial-details/trial-details-response.interface';
import { TrialResponse } from '../interfaces/trial-details/trial-response.interface';
import { Trial } from '../interfaces/trial-details/trial.interface';

@Injectable({ providedIn: 'root' })
export class SearchDataGraphQlService {
  private readonly environment: Environment = inject(ENVIRONMENT);
  private readonly url = this.environment.searchGraphQlUrl;

  constructor(private readonly httpClient: HttpClient) {}

  getCountries(params: { value: string }): Observable<CountriesResponse> {
    const data = {
      query: `
        query {
          countryAutoComplete(substring: "${params.value}")
        }
      `,
    };
    // TODO: add interceptor with headers
    return this.httpClient.post<CountriesResponse>(this.url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getAliases(params: { value: string }): Observable<AliasData> {
    const data = {
      query: `
        query {
              aliases(where: { alias: "${params.value}"} ) {
                aliasForConcept {
                  conceptOf {
                    descriptorFor {
                      knownAsTerm(where: {preferred: "true"}) {
                        alias
                  }
                }
              }
              alsoSearchFor {
                    knownAsTerm(where: {preferred: "true"}) {
                      alias
                }
              }
            }
          }
        }
      `,
    };
    return this.httpClient.post<AliasData>(this.url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getConditions(params: { value: string }): Observable<ConditionsResponse> {
    const data = {
      query: `
        query {
          autoComplete(substring: "${params.value}") {
            alias
          }
        }
      `,
    };
    return this.httpClient.post<ConditionsResponse>(this.url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getTrialDetails(request: TrialDetailsRequest): Observable<Trial> {
    const data = {
      query: `
        query {
          studies(where: {id: "${request.trialId}"}) {
              id
              title
              overall_status
              phase
              primary_purpose
              study_type
              conditions
              interventions
              protocol_type
              reports_as_closed
              start_date
              end_date
              brief_summary
              minimum_age
              maximum_age
              gender
              eligibility
              additional_info
              references
              locatedAtConnection {
                  edges {
                      status
                      reported_status
                      reported_status_since
                      node {
                          uuid
                          countryname
                          city
                          sitename
                          lon
                          lat
                      }
                  }
              }
          }
      }
      `,
    };
    return this.httpClient
      .post<TrialDetailsResponse>(this.url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .pipe(
        map((response: TrialDetailsResponse) => {
          const trialResponse: TrialResponse = response.data.studies[0];
          return {
            ...trialResponse,
            additional_info: JSON.parse(trialResponse.additional_info),
            conditions: JSON.parse(trialResponse.conditions),
            eligibility: JSON.parse(trialResponse.eligibility),
            references: JSON.parse(trialResponse.references),
          };
        }),
      );
  }
}
