import { Injectable, inject } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { ENVIRONMENT } from '@my-tomorrows/core-environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MapInitializerService {
  private readonly MARKER_CLUSTERER_SCRIPT_URL = 'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js';
  private readonly _isMapReady$ = new BehaviorSubject(false);
  private readonly environment = inject(ENVIRONMENT);

  readonly isMapReady$ = this._isMapReady$.asObservable();

  constructor() {
    if (!this._isMapReady$.value) {
      this.initMap();
    }
  }

  private initMap(): void {
    const loader = new Loader({
      apiKey: this.environment.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    Promise.all([this.loadMarkerClustererScript(), loader.importLibrary('maps'), loader.importLibrary('marker')])
      .then(() => {
        this._isMapReady$.next(true);
      })
      .catch((e) => {
        console.error(`Google map could not be initilized. Error: ${e}`);
      });
  }

  private loadMarkerClustererScript(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = this.MARKER_CLUSTERER_SCRIPT_URL;
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
      document.body.appendChild(script);
    });
  }
}
